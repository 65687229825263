import { ScrollSpy } from 'bootstrap/dist/js/bootstrap'

const bootstrapInit = () => {
  // ScrollSpy
  const scrollSpyElementList = [...document.querySelectorAll('[data-bs-spy="scroll"]')]
  ;scrollSpyElementList.forEach((scrollSpyElement) => {
    new ScrollSpy(scrollSpyElement)
  })
}

export { bootstrapInit }
